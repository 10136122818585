
































import { AnyObject, QueryParams } from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { PossibleRelatedType, SearchGroup, SearchResultsConfig } from '../../../contexts'
import { Inject } from '../../../support'
import { NavTabsItemProps } from '../../../dsl/molecules/NavTabs'
import { StructureConfigurable } from '../../../support/mixins'

import ProductsListModuleUi from '../../../modules/ProductsList/ui/ProductsList.ui.vue'

import { ISearchService, SearchServiceType } from '../../shared/services/search'
import { FullscreenLoader } from '../../shared/molecules/Loader'
import { IToastMixin, ToastMixin } from '../../shared'

import { SEARCH_RESULTS_DEFAULT_CONFIG, SEARCH_RESULTS_VIEW_KEY } from '../config/search'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<SearchResultsView>({
  name: 'SearchResultsView',
  components: { FullscreenLoader },
  created (): void {
    this.isLoading = true
    this.config = this.getComponentConfig(SEARCH_RESULTS_VIEW_KEY, SEARCH_RESULTS_DEFAULT_CONFIG)
  },

  mounted (): void {
    this.onSearchText()
  }
})
export class SearchResultsView extends Mixins<IToastMixin, StructureConfigurable>(ToastMixin, StructureConfigurable) {
  @Inject(SearchServiceType)
  protected readonly searchService!: ISearchService

  public activeTab: string = ''

  public query: QueryParams = {}

  public displayableTabs = ['products']

  public results: SearchGroup[] = []

  public isLoading: boolean = false

  /**
   * TODO: Refactor, move to another place.
   */
  public readonly searchResultsTabDefaultConfig: Record<string, AnyObject> = {
    products: {
      instance: ProductsListModuleUi
    }
  }

  /**
   * TODO: Remove to another place.
   *       Notice: It will not contain contracts of module in the future, because it will not be
   *       module.
   */
  public get component (): { instance: VueConstructor; props: AnyObject } {
    const candidate = this.results.find((result) => {
      return result.group === this.activeTab
    })

    const filtersCandidate = this.results.find((result) => {
      return result.group === PossibleRelatedType.ProductFilter
    })

    if (typeof candidate === 'undefined') {
      return {
        instance: this.searchResultsTabDefaultConfig[this.activeTab].instance,
        props: {
          data: {
            content: {
              defaultPerPage: this.perPage
            }
          }
        }
      }
    }

    return {
      instance: this.searchResultsTabDefaultConfig[this.activeTab].instance,
      props: {
        data: {
          content: {
            products: candidate.elements || [],
            defaultPerPage: this.perPage,
            defaultSort: this.hasControls ? 'default' : '',
            filters: this.hasControls && filtersCandidate ? filtersCandidate.elements : [],
            total: candidate.totalCount,
            showPagination: candidate.elements.length < candidate.totalCount!
          },
          driver: 'products_list',
          id: 'productsListSearchResults',
          isVisible: true
        }
      }
    }
  }

  /**
   * Determines the available tabs.
   */
  public get searchConfig (): SearchResultsConfig {
    return this.searchService.getConfig()
  }

  public get hasResults (): boolean {
    return this.results.length > 0 && this.results.every((result) => {
      return Array.isArray(result.elements) && result.elements.length > 0
    })
  }

  public get searchText (): string {
    if (this.$route.query && this.$route.query.q) {
      return this.$route.query.q.toString()
    }

    return ''
  }

  /**
   * Determines displayed tabs
   */
  public get tabs (): NavTabsItemProps[] {
    return Object.entries(this.searchConfig).map(([key, config]) => {
      return {
        id: key,
        label: config.label ?? key,
        active: false
      }
    }).filter((tab) => this.displayableTabs.includes(tab.id))
  }

  public get perPage (): number {
    return this.getConfigProperty('resultsPerPage')
  }

  public get hasControls (): boolean {
    return this.getConfigProperty('hasControls')
  }

  public get usePerPageParam (): boolean {
    return this.getConfigProperty('usePerPageParam')
  }

  public get useRouteParams (): boolean {
    return this.getConfigProperty('useRouteParams')
  }

  public get queryParams (): QueryParams {
    return this.query
  }

  public set queryParams (val) {
    this.query = {
      ...this.query,
      ...val
    }
  }

  public async onQueryParams (tab: string): Promise<void> {
    this.activeTab = tab
    await this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        scope: tab
      }
    })
  }

  @Watch('searchText')
  protected onSearchText (): void {
    let page

    if (this.$route.query && this.$route.query.scope) {
      this.activeTab = this.$route.query.scope.toString()
    } else {
      this.activeTab = this.displayableTabs[0]
    }

    if (this.$route.query.page) {
      page = String(this.$route.query.page)
    } else {
      page = 1
    }

    if (this.usePerPageParam && !this.useRouteParams) {
      this.searchService.search(this.searchText, { perPage: this.perPage, page }).then((response) => {
        this.results = response
      }).catch((e) => {
        this.showToast((e as Error).message, 'danger')
      }).finally(() => {
        this.isLoading = false
      })
    } else {
      const sort = typeof this.$route.query.sort === 'string' ? this.$route.query.sort : undefined
      this.searchService.search(this.searchText, {
        sort: sort ?? 'default',
        page
      }).then((response) => {
        this.results = response
      }).catch((e) => {
        this.showToast((e as Error).message, 'danger')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}

export default SearchResultsView
